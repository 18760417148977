// Login.js
import React, { useState, useEffect } from 'react';
import {
  Flex,
  Box,
  Heading,
  Text,
  Button,
  SimpleGrid,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  Input,
  Link as ChakraLink,
  useToast,
  Container
} from '@chakra-ui/react';
import { AiOutlineMail, AiOutlineLock } from 'react-icons/ai';
import {
  signIn,
  createUser
} from './auth.utils';

import SEO from './components/SEO';

import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { fetchLastForms } from './firebase.utils';
import { useNavigate } from 'react-router-dom';
import FormCard from './components/FormCard';
import { ChevronDownIcon } from '@chakra-ui/icons';

const Login = ({ onUserAuthenticated, language }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignup, setIsSignup] = useState(false); 
  const toast = useToast();
  const { t } = useTranslation();
  const location = useLocation();
  const [forms, setForms] = useState([]);
  const navigate = useNavigate();

  const languageNames = {
    'es': 'Español',
    'en': 'English',
    'pt': 'Português'
  };

  const getRedirectParam = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('redirect');
  };


  useEffect(() => {
    const getForms = async () => {
      const lastForms = await fetchLastForms(language);
      setForms(lastForms);
    };

    getForms();
  }, [language]);

  const validateForm = () => {
    if (!email) {
      toast({
        title: 'Error',
        description: 'Please enter your email.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    if (!password) {
      toast({
        title: 'Error',
        description: 'Please enter your password.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    return true;
  };

  const handleLogin = async () => {
    if (!email || !password) {
      toast({
        title: 'Error',
        description: 'Email and password are required.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const { error, token } = await signIn(email, password);
    

    if (error) {
      toast({
        title: t('login_failed'),
        description: error.response?.data?.error || t('error_occurred_trying_to_sign_in'),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } else {
      onUserAuthenticated(token, getRedirectParam());
      toast({
        title: t('login_successful'),
        description: t('now_logged_in'),
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    }
  };


  const handleSignup = async () => {
    if (!email || !password) {
      toast({
        title: 'Error',
        description: 'Email and password are required.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      // Call custom createUser function which calls the Firebase Function for creating a user
      const response = await createUser(email, password);
      console.log('response',response);
      if (response.user_created) {
        // Handle user successfully created
        toast({
          title: 'Account Created',
          description: 'Your account has been created.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        onUserAuthenticated(response.token, getRedirectParam());
      } else if (response.user_present) {
        // Handle user already present; show an error message
        toast({
          title: 'Account Error',
          description: 'An account with this email already exists.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Account Creation Failed',
        description: error.message || 'Failed to create an account.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };


  return (
    <Flex
      direction="column"
      align="center"
      w="full"
      minH="100vh"
      justify="space-between"
      pt={10}
    >
      {/* Header */}
      <Flex
  as="header"
  w="full"
  p={5}
  bg="blue.600"
  color="white"
  align="center"
  justify="space-between"
  position="fixed"
  top={0}
  left={0}
  right={0}
  zIndex="docked"
>
  <Container maxW="container.xl" display="flex" justifyContent="space-between" alignItems="center">
    <SEO language={language} />
    <Flex align="center">
      <Heading as="h1" size="lg" letterSpacing="tighter" color="white">
        FormBuilderGPT
      </Heading>
      <Box ml="2">
        <img src="/logo-white.png" alt={t('datascope_logo_alt_text')} style={{ maxWidth: '100px' }} />
      </Box>
    </Flex>

    <Menu>
      <MenuButton as={Button} variant="unstyled">
        <Flex align="right">
          <Text>
            {languageNames[language.toLowerCase()]}
          </Text>
          <ChevronDownIcon />
        </Flex>
      </MenuButton>
      <MenuList bg="white">
        <MenuItem as='a' href="/?language=es" bg="white" color="grey">
          Español
        </MenuItem>
        <MenuItem as='a' href="/?language=en" bg="white" color="grey">
          English
        </MenuItem>
        <MenuItem as='a' href="/?language=pt" bg="white" color="grey">
          Português
        </MenuItem>
      </MenuList>
    </Menu>
  </Container>
</Flex>

      {/* Content */}
      <Flex
        direction={{ base: 'column', lg: 'row' }}
        align="center"
        justify="center"
        flex="1"
        p={5}
        mt={10}
      >
        <Container maxW="container.xl">
          <Stack
            direction={{ base: 'column', md: 'row' }}
            align="center"
            justify="space-around"
            h="full"
            spacing={10}
            p={{ base: 2, md: 5 }}
          >
            <Box flex="1" mb={[5, 0]} textAlign="center">
              <Heading as="h2" size="xl" mb={4}>
                {t('craft_forms_with_ai')}
              </Heading>
              <Text fontSize="lg" color="gray.600" mb={6}>
                {t('goodbye_complex_builders')}
              </Text>
              <video
                loading="lazy"
                muted
                src="ai-innovation-forms.mp4"
                type="video/mp4"
                autoPlay
                loop
                borderRadius="md"
                boxShadow="xl"
                style={{ maxWidth: '500px', width: '100%' }} // Ensures video doesn't overflow on small screens
              />
            </Box>

            <VStack
              spacing={6}
              bg="white"
              p={8}
              shadow="lg"
              rounded="xl"
              w="full"
              maxW={['full', 'md']}
              align="stretch"
            >
              <Stack direction="row" mb={4} spacing={4} justify="center">
                <Button variant={isSignup ? "outline" : "solid"} onClick={() => setIsSignup(false)} flex={1} colorScheme="blue">
                  {t('login')}
                </Button>
                <Button variant={isSignup ? "solid" : "outline"} onClick={() => setIsSignup(true)} flex={1} colorScheme="green">
                  {t('sign_up')}
                </Button>
              </Stack>

              <Input
                placeholder={t('email_address')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                size="lg"
                variant="filled"
              />
              <Input
                placeholder={t('password')}
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                size="lg"
                variant="filled"
                mb={6}
              />
              <Button
                leftIcon={isSignup ? <AiOutlineMail /> : <AiOutlineLock />}
                size="lg"
                variant="solid"
                onClick={isSignup ? handleSignup : handleLogin}
                colorScheme={isSignup ? "green" : "blue"}
                isFullWidth
              >
                {isSignup ? t('create_account') : t('sign_in')}
              </Button>
              {!isSignup && (
                <ChakraLink as={RouterLink} to="https://mydatascope.com/users/password/new" color="blue.500" alignSelf="flex-end" fontSize="sm">
                  {t('remember_password')}
                </ChakraLink>
              )}
            </VStack>
          </Stack>
        </Container>
      </Flex>


      <VStack spacing={4} m={4}>
      <Heading as="h2" mb={5} >{t('latest_forms')}</Heading>
      <SimpleGrid columns={[1, null, 2, 3]} spacing={5}>
        {forms.map((form) => (
          <FormCard key={form.libraryId } form={form.data} />
        ))}
      </SimpleGrid>
      <Button onClick={() => navigate(`/forms-library?language=${language}`)}>{t('view_more')}</Button> {/* Modify the navigate path as needed */}
    </VStack>

      {/* Footer */}
      <Flex w="full" p={4} bg="blue.600" align="center">
        <Container maxW="container.xl">
          <Text color="white" textAlign="center">
            &copy; {new Date().getFullYear()} {t('all_rights_reserved')}
          </Text>
        </Container>
      </Flex>
    </Flex>
  );
};

export default Login;